// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-fedex-js": () => import("/opt/build/repo/src/pages/work/fedex.js" /* webpackChunkName: "component---src-pages-work-fedex-js" */),
  "component---src-pages-work-gorgiality-js": () => import("/opt/build/repo/src/pages/work/gorgiality.js" /* webpackChunkName: "component---src-pages-work-gorgiality-js" */),
  "component---src-pages-work-jmate-js": () => import("/opt/build/repo/src/pages/work/jmate.js" /* webpackChunkName: "component---src-pages-work-jmate-js" */),
  "component---src-pages-work-letterforms-js": () => import("/opt/build/repo/src/pages/work/letterforms.js" /* webpackChunkName: "component---src-pages-work-letterforms-js" */),
  "component---src-pages-work-planet-queen-js": () => import("/opt/build/repo/src/pages/work/planet-queen.js" /* webpackChunkName: "component---src-pages-work-planet-queen-js" */),
  "component---src-pages-work-tedx-js": () => import("/opt/build/repo/src/pages/work/tedx.js" /* webpackChunkName: "component---src-pages-work-tedx-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

